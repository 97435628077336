import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import "./font/font.css";
import UuidLayout from "./components/UuidLayout";
import ScrollMenu from "./pages/ScrollMenuPage";
import MenuItemDetail from "./pages/MenuDetailPage";
import Cart from "./pages/CartPage";
import LoadingPage from "./pages/LoadingPage";
import ErrPageHandler from "./pages/ErrPage";
import Main from "./pages/MainPage";
import PaymentResult from "./pages/PaymentResultPage";
import PaymentSuccess from "./pages/PaymentSuccessPage";
import PaymentFailure from "./pages/PaymentFailurePage";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* UUID를 사용하는 경로 */}
          <Route path="/:uuid" element={<UuidLayout />}>
            {/* 하위 라우트 */}
            <Route index element={<LoadingPage />} />
          </Route>
          <Route path="detail" element={<MenuItemDetail />} />
          <Route path="main" element={<Main />} />
          <Route path="menu" element={<ScrollMenu />} />
          <Route path="cart" element={<Cart />} />
          {/* 결제 결과 */}
          <Route path="/payment-result" element={<PaymentResult />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-failure" element={<PaymentFailure />} />

          {/* 에러 페이지 */}
          <Route path="/error" element={<ErrPageHandler />} />

          {/* 나머지 경로는 에러 페이지로 이동 */}
          <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
