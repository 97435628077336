import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";

const FooterContainer = ({ onAddToCart, handlePayment }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isCartPage = location.pathname.includes("/cart");
  const buttonText = isCartPage ? "메뉴 추가" : "장바구니 담기";

  const handleButtonClick = () => {
    if (isCartPage) {
      navigate(`/menu`);
    } else {
      onAddToCart(); // 상위 컴포넌트에서 넘겨받은 함수 호출
    }
  };

  return (
    <Footer
      buttonText={buttonText}
      onButtonClick={handleButtonClick}
      onPaymentClick={handlePayment}
    />
  );
};

export default FooterContainer;
