import ServerConfig from "../ServerConfig";
import api from "../utils/api";

export const getInitialParamsFromURL = () => {
  // URL 경로에서 :uuid 추출
  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/");
  const uuid = pathSegments[1] || null;

  // URL 쿼리에서 tableId 추출
  const queryParams = new URLSearchParams(window.location.search);
  const tableId = queryParams.get("tableId") || null;

  console.log("UUID:", uuid);
  console.log("tableId:", tableId);

  return { idx: uuid, tableId };
};

const initialState = {
  accessToken: null,
  idx: null,
  tableId: null,
};

// 액션 타입
const OAUTH_REQUEST_SET_TOKEN = "OAUTH_REQUEST_SET_TOKEN";

// 액션 생성자
export const setToken = (token) => ({
  type: OAUTH_REQUEST_SET_TOKEN,
  payload: token,
});

// Acess Token 가져오기
export const getAccessToken = (state) => {
  return state.auth?.accessToken || null;
};

export const getIdx = (state) => {
  return state.auth?.idx || null;
};

export const getTableId = (state) => {
  return state.auth?.tableId || null;
};

// 토큰 유효성 검사
export const checkToken = (token) => {
  return api.post("/oauth/check_token", {
    token: token,
  });
};

const dispatchToken = (dispatch, accessToken, idx, tableId) => {
  dispatch({
    type: OAUTH_REQUEST_SET_TOKEN,
    payload: { accessToken, idx, tableId },
  });
};

const commonRequestToken = (requestData) => (dispatch, getState) => {
  return api
    .post("/oauth/token", requestData)
    .then(({ data }) => {
      if (data.data.active) {
        const idx = data.data.idx;
        const accessToken = data.data.token;
        const queryParams = new URLSearchParams(window.location.search);
        const tableId = queryParams.get("tableId") || null;
        dispatchToken(dispatch, accessToken, idx, tableId);
        cleanupOAuth(accessToken);
      } else {
        // TODO: 에러페이지로 이동
        // TODO: try catch -> 에러시 에러페이지 이동
      }
    })
    .catch((error) => {
      console.error("API Error: ", error);
      // TODO: 에러 페이지로 이동
    });
};

// 토큰 발행
export const requestNewToken = () => (dispatch, getState) => {
  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/");
  const uuid = pathSegments[1] || null;
  const requestData = { idx: uuid };
  return commonRequestToken(requestData)(dispatch, getState);
};

// 토큰 새로고침
export const requestTokenRefresh = () => (dispatch, getState) => {
  const idx = getState()?.auth?.idx;
  return api.post("/oauth/token", { idx: idx }).then(({ data }) => {
    dispatch({ type: "OAUTH_REQUEST_SET_TOKEN", payload: data });
  });
};

// OAuth 클린업
export const cleanupOAuth = (token) => {
  api.setAccessToken(ServerConfig.TOKEN_AUTHORIZATION + `&&` + token);
};

// 리듀서
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case OAUTH_REQUEST_SET_TOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        idx: action.payload.idx,
        tableId: action.payload.tableId,
      };
    default:
      return state;
  }
}
