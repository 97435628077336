import React from "react";
import { useNavigate } from "react-router-dom";
//import queryString from "query-string";
import HeaderContainer from "../common/HeaderContainer";
import Main from "../../components/Main";
import { connect } from "react-redux";

const MainContainer = ({ storeName }) => {
  //const location = useLocation();
  //  const { uuid } = useParams();

  //  쿼리 파라미터에서 tableId 추출
  //  const { tableId } = queryString.parse(location.search);

  const navigate = useNavigate();

  const handleTableClick = () => {
    navigate(`/menu`);
  };

  return (
    <div>
      <HeaderContainer />
      <Main handleTableClick={handleTableClick} storeName={storeName} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  storeName: state.menu.storeName,
});

export default connect(mapStateToProps)(MainContainer);
