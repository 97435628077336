import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../modules/cart";
import MenuDetail from "../../components/Menu/MenuDetail";
import FooterContainer from "../common/FooterContainer";

const MenuDetailContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const item = location.state?.item;

  const [selectedHotOrIce, setSelectedHotOrIce] = useState("Hot");
  const [selectedSyrupOptions, setSelectedSyrupOptions] = useState("");
  const [selectedShotOptions, setSelectedShotOptions] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(item?.price || 0);

  const { hotOrIce, syrupOptions, shotOptions } = item?.options || {};

  const calculateTotalPrice = useCallback(() => {
    let total = item.price;
    if (selectedSyrupOptions) {
      const selectedSyrup = syrupOptions.find(
        (syrup) => syrup.name === selectedSyrupOptions
      );
      if (selectedSyrup) total += selectedSyrup.additionalCost;
    }
    if (selectedShotOptions) {
      const selectedShot = shotOptions.find(
        (shot) => shot.name === selectedShotOptions
      );
      if (selectedShot) total += selectedShot.additionalCost;
    }
    return total * quantity;
  }, [
    item.price,
    selectedSyrupOptions,
    selectedShotOptions,
    quantity,
    syrupOptions,
    shotOptions,
  ]);

  useEffect(() => {
    setTotalPrice(calculateTotalPrice());
  }, [calculateTotalPrice]);

  const handleAddToCart = () => {
    const cartItem = {
      id: item.id,
      name: item.name,
      price: item.price,
      quantity,
      totalPrice,
      options: {
        hotOrIce: selectedHotOrIce,
        syrup: selectedSyrupOptions,
        shot: selectedShotOptions,
      },
      imgUrl: item.imgUrl,
    };
    dispatch(addToCart(cartItem));
    alert(`${item.name}가 장바구니에 담겼습니다.`);
    navigate(`/menu`);
  };

  const handlePayment = () => {
    alert(`총 결제 금액: ￦${totalPrice}`);
  };

  return (
    <div>
      <MenuDetail
        item={item}
        hotOrIce={hotOrIce}
        syrupOptions={syrupOptions}
        shotOptions={shotOptions}
        selectedHotOrIce={selectedHotOrIce}
        selectedSyrupOptions={selectedSyrupOptions}
        selectedShotOptions={selectedShotOptions}
        quantity={quantity}
        totalPrice={totalPrice}
        handleRadioButtonClick={(value, type) => {
          if (type === "hotOrIce") setSelectedHotOrIce(value);
          if (type === "syrupOptions") setSelectedSyrupOptions(value);
          if (type === "shotOptions") setSelectedShotOptions(value);
        }}
        handleQuantityIncrease={() => setQuantity((q) => Math.min(q + 1, 99))}
        handleQuantityDecrease={() => setQuantity((q) => Math.max(q - 1, 1))}
        handlePayment={handlePayment}
      />
      <FooterContainer onAddToCart={handleAddToCart} />
    </div>
  );
};

export default MenuDetailContainer;
