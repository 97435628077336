import React from "react";
import ReactDOM from "react-dom/client"; // React 18 사용
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import { useCustomAlert } from "./components/CustomAlert/CustomAlert";
import { store, persistor } from "./modules/store";
import { PersistGate } from "redux-persist/integration/react";

window.payupPaymentSubmit = function (payForm) {
  console.log("payupPaymentSubmit 호출됨");
  console.log("폼 데이터:", new FormData(payForm));

  return;
};
const Home = () => {
  const { showAlert, AlertComponent } = useCustomAlert();

  // window.alert 대체
  React.useEffect(() => {
    window.alert = (message) => {
      showAlert(message);
    };
  }, [showAlert]);

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
      {AlertComponent}
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Home />);
