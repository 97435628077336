import axios from "axios";
import ServerConfig from "../ServerConfig";

// Axios 기본 설정
const api = axios.create({
  baseURL: ServerConfig.ROOT_URL, // 서버 URL
  timeout: 10000, // 10초 초과 시 요청 실패 처리
  headers: {
    "Content-Type": "application/json",
  },
});

api.setAccessToken = (accessToken) => {
  api.defaults.headers.common.Authorization = accessToken;
};

// 인터셉터 추가 함수
api.addInterceptor = (onFulfilled, onRejected) => {
  api.interceptors.request.use(onFulfilled, onRejected);
};
export default api;
