import React from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";

// UUID 정규식
const uuidRegex =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

// UUID 검증 레이아웃
const UuidLayout = () => {
  const { uuid } = useParams();

  // UUID 검증
  if (!uuidRegex.test(uuid)) {
    return <Navigate to="/error" />;
  }

  // Outlet을 통해 자식 라우트 렌더링
  return <Outlet />;
};

export default UuidLayout;
