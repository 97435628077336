import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // localStorage에 저장
import cartReducer from "./cart";
import configureAndConnectOAuthInterceptor from "../utils/oauthInterceptor";
import authReducer from "./OAuth";
import handleActions from "./menu";

// Redux persist 설정
const persistConfig = {
  key: "root",
  storage, // 상태를 저장할 storage (localStorage 사용)
};

const rootReducer = combineReducers({
  cart: cartReducer,
  auth: authReducer,
  menu: handleActions,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux Store 생성
const store = createStore(persistedReducer, applyMiddleware(thunk));

// Redux Persist 초기화
const persistor = persistStore(store);

// OAuth 인터셉터 연결
configureAndConnectOAuthInterceptor(store);

export { store, persistor };
