import React from "react";
import styles from "./menuDetail.module.css";

const MenuDetail = ({
  item,
  hotOrIce,
  syrupOptions,
  shotOptions,
  selectedHotOrIce,
  selectedSyrupOptions,
  selectedShotOptions,
  quantity,
  totalPrice,
  handleRadioButtonClick,
  handleQuantityIncrease,
  handleQuantityDecrease,
}) => {
  return (
    <div>
      <div className={styles["menu-item-detail"]}>
        <img
          src={item.imgUrl || "/img/기본이미지.png"}
          alt={item.name}
          className={styles["menu-item-detail-image"]}
        />
        <h2 className={styles["menu-name"]}>{item.name}</h2>
        <hr />
        <div className={styles["menu-price"]}>
          <p>가격</p>
          <p>￦{item.price.toLocaleString()}</p>
        </div>
        <hr />
        <div className={styles["menu-item-detail-options"]}>
          {/* Hot/Ice 옵션 */}
          {hotOrIce?.length > 0 && (
            <div>
              <p>Hot / Ice</p>
              <p className={styles["max-select-message"]}>
                (최대 1개 선택 가능)
              </p>
              {hotOrIce.map((option, index) => (
                <label key={index} className={styles["options"]}>
                  <div>
                    <input
                      type="radio"
                      name="hotOrIce"
                      value={option}
                      checked={selectedHotOrIce === option}
                      onClick={() => handleRadioButtonClick(option, "hotOrIce")}
                      onChange={() => {}}
                    />
                    <span>{option}</span>
                  </div>
                </label>
              ))}
            </div>
          )}
          {/* 시럽 옵션 */}
          {syrupOptions?.length > 0 && (
            <div>
              <p>시럽 추가</p>
              <p className={styles["max-select-message"]}>
                (최대 1개 선택 가능)
              </p>
              {syrupOptions.map((syrup, index) => (
                <label key={index} className={styles["options"]}>
                  <div>
                    <input
                      type="radio"
                      name="syrupOptions"
                      value={syrup.name}
                      checked={selectedSyrupOptions === syrup.name}
                      onClick={() =>
                        handleRadioButtonClick(syrup.name, "syrupOptions")
                      }
                      onChange={() => {}}
                    />
                    <span>{syrup.name}</span>
                  </div>
                  <span>+{syrup.additionalCost}원</span>
                </label>
              ))}
            </div>
          )}
          {/* 샷 옵션 */}
          {shotOptions?.length > 0 && (
            <div>
              <p>에스프레소샷 추가</p>
              <p className={styles["max-select-message"]}>
                (최대 1개 선택 가능)
              </p>
              {shotOptions.map((shot, index) => (
                <label key={index} className={styles["options"]}>
                  <div>
                    <input
                      type="radio"
                      name="shotOptions"
                      value={shot.name}
                      checked={selectedShotOptions === shot.name}
                      onClick={() =>
                        handleRadioButtonClick(shot.name, "shotOptions")
                      }
                      onChange={() => {}}
                    />
                    <span>{shot.name}</span>
                  </div>
                  <span>+{shot.additionalCost}원</span>
                </label>
              ))}
            </div>
          )}
        </div>
        <hr />
        <div className={styles["menu-quantity"]}>
          <p>수량</p>
          <div>
            <button
              onClick={handleQuantityDecrease}
              className={styles["quantity-btn"]}
            >
              -
            </button>
            <div className={styles["quantity"]}>{quantity}</div>
            <button
              onClick={handleQuantityIncrease}
              className={styles["quantity-btn"]}
            >
              +
            </button>
          </div>
        </div>
        <hr />
        <div className={styles["menu-total-price"]}>
          <p>총 주문 금액</p>
          <p className={styles["total-Price"]}>
            ￦{totalPrice.toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MenuDetail;
