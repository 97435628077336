import {
  checkToken,
  getAccessToken,
  requestTokenRefresh,
  cleanupOAuth,
} from "../modules/OAuth";
import api from "./api";

// 인증 관련 URL 목록
const TOKEN_URLS = ["/oauth/check_token", "/oauth/token"];

// 인터셉터 등록 여부 확인 플래그
let isRegistered = false;

/*
 * Axios 요청 인터셉터의 onFulfilled 함수
 * @param {Object} AxiosRequestConfig - Axios 요청 설정 객체
 * @param {Function} dispatch - Redux dispatch 함수
 * @param {Function} getState - Redux 상태를 가져오는 함수
 */
const onRequestFulfilled = (AxiosRequestConfig, dispatch, getState) => {
  // 인증 관련 요청은 인터셉터 처리에서 제외
  if (TOKEN_URLS.includes(AxiosRequestConfig.url)) {
    return AxiosRequestConfig;
  }

  const accessToken = getAccessToken(getState());

  return new Promise((resolve) => {
    checkToken(accessToken).then(({ data: { active } }) => {
      if (active) {
        // 토큰이 유효하면 그대로 요청 진행
        cleanupOAuth(accessToken);
        resolve(AxiosRequestConfig);
        return;
      }

      // 토큰이 만료된 경우 새 토큰을 발급받고 요청 진행
      requestTokenRefresh()(dispatch, getState).then(() =>
        resolve(AxiosRequestConfig)
      );
    });
  });
};

/*
 * Axios 요청 인터셉터의 onRejected 함수
 * @param {Object} error - Axios 오류 객체
 * @returns {Object} - 오류 객체 그대로 반환
 */
const onRequestRejected = (error) => error;

/*
 * OAuth 인터셉터를 설정하고 Axios에 등록하는 함수
 * @param {Object} store - Redux store 객체 ({ dispatch, getState })
 */
const configureAndConnectOAuthInterceptor = ({ dispatch, getState }) => {
  if (isRegistered) return;

  api.addInterceptor(
    (config) => onRequestFulfilled(config, dispatch, getState),
    onRequestRejected
  );

  isRegistered = true;
};

export default configureAndConnectOAuthInterceptor;
