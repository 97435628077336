import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

const MenuItem = ({ item }) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("MenuItem 렌더링됨");
    console.log("Item 데이터:", item);
  }, [item]);

  const handleClick = () => {
    navigate(`/detail`, { state: { item } });
  };

  return (
    <div className={styles["menu-item"]} onClick={handleClick}>
      <div className={styles["menu-item-details"]}>
        <h4 className={styles["menu-item-name"]}>{item.name}</h4>
        <p className={styles["menu-item-desc"]}>{item.description}</p>
        <p className={styles["menu-item-price"]}>
          ￦{item.price.toLocaleString()}
        </p>
      </div>
      <div className={styles["menu-item-img-container"]}>
        <img
          src={item.imgUrl || "/img/기본이미지.png"}
          alt={item.name}
          className={styles["menu-item-img"]}
          onError={() => console.error("이미지 로드 실패:", item.imgUrl)}
        />
      </div>
    </div>
  );
};

export default MenuItem;
