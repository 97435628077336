import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import {
  getCartItems,
  //  updateCart,
  //  calculateTotalPrice,
} from "../../modules/cart";

const HeaderContainer = () => {
  const navigate = useNavigate();
  const location = useLocation(); // 현재 경로 확인
  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_API_URL;

  // Redux 상태에서 장바구니 데이터 가져오기
  const cartItems = useSelector(getCartItems) || [];

  // 장바구니에 담긴 전체 수량 계산
  const totalQuantity = cartItems.reduce((acc, item) => acc + item.quantity, 0);

  // 뒤로가기 버튼 표시 여부
  const showBackButton = location.pathname !== `/main`;

  // storename 클릭 시 첫 화면 이동
  const goMain = () => {
    navigate(`/menu`);
  };

  // 장바구니 아이콘 클릭 시 이동
  const handleCartClick = () => {
    navigate(`/cart`);
  };

  // 뒤로가기 버튼 클릭 시
  const goBack = () => {
    navigate(-1);
  };

  // API를 통해 장바구니 데이터 불러오기 및 Redux에 저장
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        //const response = await fetch(`${API_URL}/cart`);
        //if (!response.ok) {
        //  throw new Error("장바구니 데이터를 불러올 수 없습니다.");
        //}
        //const data = await response.json();
        //dispatch(updateCart(data)); // Redux에 장바구니 데이터 저장
        //dispatch(calculateTotalPrice()); // 총 금액 재계산
      } catch (error) {
        console.error("장바구니 데이터 로드 에러:", error);
      }
    };

    fetchCartItems();
  }, [dispatch, API_URL]);

  return (
    <Header
      showBackButton={showBackButton}
      totalQuantity={totalQuantity}
      onGoBack={goBack}
      onGoMain={goMain}
      onCartClick={handleCartClick}
    />
  );
};

export default HeaderContainer;
