import { createAction, handleActions } from "redux-actions";
import { createSelector } from "reselect";

// 초기 상태
const initialState = {
  items: [], // 장바구니 항목 리스트
  totalPrice: 0, // 총 금액
};

// 액션 타입
const ADD_TO_CART = "cart/ADD_TO_CART";
const REMOVE_FROM_CART = "cart/REMOVE_FROM_CART";
const UPDATE_CART = "cart/UPDATE_CART";
const CALCULATE_TOTAL_PRICE = "cart/CALCULATE_TOTAL_PRICE";

// 액션 생성자
export const addToCart = createAction(ADD_TO_CART, (item) => {
  console.log("Redux로 전달된 아이템:", item); // 확인용 로그
  return item;
});

export const removeFromCart = createAction(
  REMOVE_FROM_CART,
  (itemId) => itemId
);

export const updateCart = createAction(
  UPDATE_CART,
  (updatedItems) => updatedItems
);
export const calculateTotalPrice = createAction(CALCULATE_TOTAL_PRICE);

// 리듀서
const cartReducer = handleActions(
  {
    // 아이템 추가
    [ADD_TO_CART]: (state, { payload }) => {
      console.log("ADD_TO_CART payload:", payload); // 디버깅용 로그

      // 기존 항목 찾기 (id와 옵션이 동일한 경우)
      const existingItemIndex = state.items.findIndex(
        (item) =>
          item.id === payload.id &&
          JSON.stringify(item.options) === JSON.stringify(payload.options)
      );

      let updatedItems;

      if (existingItemIndex !== -1) {
        // 이미 존재하는 항목 -> 수량과 총 가격 업데이트
        updatedItems = state.items.map((item, index) =>
          index === existingItemIndex
            ? {
                ...item,
                quantity: item.quantity + payload.quantity, // 수량 증가
                totalPrice: item.totalPrice + payload.totalPrice, // 총 가격 증가
              }
            : item
        );
      } else {
        // 새로운 항목 -> 목록에 추가
        updatedItems = [...state.items, payload];
      }

      // 총 금액 계산
      const totalPrice = updatedItems.reduce(
        (sum, item) => sum + item.price * item.quantity,
        0
      );

      return {
        ...state,
        items: updatedItems,
        totalPrice,
      };
    },

    // 아이템 삭제
    [REMOVE_FROM_CART]: (state, { payload: itemId }) => {
      const updatedItems = state.items.filter((item) => item.id !== itemId);

      return {
        ...state,
        items: updatedItems,
        totalPrice: updatedItems.reduce(
          (sum, item) => sum + item.price * item.quantity,
          0
        ),
      };
    },

    // 장바구니 전체 업데이트
    [UPDATE_CART]: (state, { payload: updatedItems }) => {
      const mergedItems = state.items.map((existingItem) => {
        const serverItem = updatedItems.find(
          (item) =>
            item.id === existingItem.id &&
            JSON.stringify(item.options) ===
              JSON.stringify(existingItem.options)
        );

        if (serverItem) {
          // 서버 아이템과 기존 아이템 병합 (quantity와 totalPrice 정확히 합산)
          return {
            ...existingItem,
            quantity: serverItem.quantity, // 서버 수량으로 동기화
            totalPrice: serverItem.totalPrice, // 서버 총 금액으로 동기화
          };
        }

        return existingItem; // 기존 아이템 유지
      });

      // 새 아이템 추가 (서버 데이터 중 기존 상태에 없는 아이템)
      updatedItems.forEach((serverItem) => {
        const existsInState = mergedItems.some(
          (item) =>
            item.id === serverItem.id &&
            JSON.stringify(item.options) === JSON.stringify(serverItem.options)
        );

        if (!existsInState) {
          mergedItems.push(serverItem);
        }
      });

      return {
        ...state,
        items: mergedItems,
        totalPrice: mergedItems.reduce(
          (sum, item) => sum + item.price * item.quantity,
          0
        ),
      };
    },

    // 총 금액 재계산
    [CALCULATE_TOTAL_PRICE]: (state) => {
      const totalPrice = state.items.reduce(
        (sum, item) => sum + item.price * item.quantity,
        0
      );

      return {
        ...state,
        totalPrice,
      };
    },
  },
  initialState
);

export default cartReducer;

// 셀렉터 함수

// 기본 셀렉터
const selectCart = (state) => state.cart;

// 메모이제이션된 셀렉터
export const getCartItems = createSelector([selectCart], (cart) => cart.items);

export const getTotalPrice = createSelector([selectCart], (cart) =>
  cart.items.reduce((sum, item) => sum + item.price * item.quantity, 0)
);
