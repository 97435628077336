import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import LoadingContainer from "../containers/common/LoadingContainer";

const LoadingPage = () => {
  const location = useLocation(); // 현재 URL 정보를 가져옴
  const navigate = useNavigate(); // 페이지 전환에 사용
  const { uuid } = useParams(); // URL에서 uuid 추출 (shopId 역할)

  // 쿼리 파라미터에서 tableId 추출
  const { tableId } = queryString.parse(location.search);

  // shopId와 tableId 유효성 검사 및 에러 페이지로 이동
  useEffect(() => {
    if (!uuid || !tableId) {
      console.error(
        "shopId 또는 tableId가 없습니다. 에러 페이지로 이동합니다."
      );
      navigate("/error");
    }
  }, [uuid, tableId, navigate]);

  // 로딩 완료 후 호출될 함수
  const handleLoadFinished = () => {
    const nextPage = `/main`; // 이동할 경로 설정
    console.log("Navigating to:", nextPage); // 경로 확인을 위한 로그
    navigate(nextPage); // 페이지 전환
  };

  return (
    <LoadingContainer
      onLoadFinished={handleLoadFinished} // 로딩 완료 시 호출될 함수 전달
    />
  );
};

export default LoadingPage;
