import { handleActions } from "redux-actions";
import menuData from "../db/data.json";
import api from "../utils/api";

// 초기 상태 정의
const initialState = {
  categories: [],
  items: {},
  shopInfo: null,
  tableId: null,
  shopName: "",
  shopId: null,
  menus: {},
};

// 액션 타입 정의
export const GET_CATEGORIES = "menu/GET_CATEGORIES";
const GET_ITEMS_BY_CATEGORY = "GET_ITEMS_BY_CATEGORY";
const GET_ITEM_BY_NAME = "GET_ITEM_BY_NAME";
const GET_ITEM_OPTIONS = "GET_ITEM_OPTIONS";
const GET_STORE_INFO = "GET_STORE_INFO";
const GET_STORE_NAME = "GET_STORE_NAME";
const GET_MENUS = "GET_MENUS";
const SET_TABLE_ID = "SET_TABLE_ID";

export const getStoreInfo = (shopId) => (dispatch) => {
  api
    .post(`/api/shops/${shopId}`)
    .then((data) => {
      if ("SUCCESS" === data.data.status) {
        dispatch({
          type: GET_STORE_INFO,
          payload: data.data.data,
        });
      }
    })
    .catch((error) => {
      console.error("getStoreInfo:", error);
    });
};

// 리듀서
/*
export default handleActions = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return { ...state, categories: action.categories };
    case GET_ITEMS_BY_CATEGORY:
      return {
        ...state,
        items: { ...state.items, [action.category]: action.items },
      };
    case GET_ITEM_BY_NAME:
      return { ...state, item: action.item };
    case GET_ITEM_OPTIONS:
      return { ...state, itemOptions: action.options };
    case GET_STORE_INFO:
      return { ...state, shopInfo: action.shopInfo };
    case GET_STORE_NAME:
      return { ...state, shopName: action.shopName };
    case GET_MENUS:
      return { ...state, menus: action.menus };
    case SET_TABLE_ID:
      return { ...state, tableId: action.tableId };
    default:
      return state;
  }
};
*/

export default handleActions(
  {
    [SET_TABLE_ID]: (state, action) => ({
      ...state,
      tableId: action.payload,
    }),
    [GET_STORE_INFO]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [GET_CATEGORIES]: (state, action) => ({
      ...state,
      categories: action.payload,
    }),
    [GET_MENUS]: (state, action) => ({
      ...state,
      menus: action.menus,
    }),
  },
  initialState
);

// 카테고리 목록을 반환하는 함수
export const getCategories = (shopId) => (dispatch) => {
  api
    .post(`/api/categories/${shopId}`)
    .then((data) => {
      console.log("Categories API response:", data.data); // 응답 데이터 확인
      if (data.data.status === "SUCCESS") {
        dispatch({
          type: GET_CATEGORIES,
          payload: data.data.data, // 서버에서 받은 데이터 저장
        });
      }
    })
    .catch((error) => {
      console.error("getCategories:", error);
    });
};

// 특정 카테고리에 해당하는 메뉴 아이템을 반환하는 함수
export const getItemsByCategory = (category) => {
  return {
    type: GET_ITEMS_BY_CATEGORY,
    items: menuData.items[category] || [],
  };
};

// 특정 메뉴 항목을 이름으로 검색하는 함수
export const getItemByName = (name) => {
  return {
    type: GET_ITEM_BY_NAME,
    item:
      Object.values(menuData.items)
        .flat()
        .find((item) => item.name === name) || null,
  };
};

// 메뉴 아이템에 추가된 옵션을 반환하는 함수
export const getItemOptions = (category, itemName) => {
  return {
    type: GET_ITEM_OPTIONS,
    options:
      menuData.items[category]?.find((item) => item.name === itemName)
        ?.options || {},
  };
};

// 가게 이름 반환
export const getStoreName = (storeId) => {
  return {
    type: GET_STORE_NAME,
    shopName:
      menuData.storeId === storeId ? menuData.storeName : "Unknown Shop",
  };
};

// 가게 메뉴 반환
export const getMenus = (shopId) => (dispatch) => {
  console.log("Calling getMenus with shopId:", shopId);

  return api
    .post(`/api/items/${shopId}`)
    .then((response) => {
      console.log("getMenus API response:", response.data); // 응답 데이터 확인

      // 응답 데이터를 바로 상태에 저장
      dispatch({
        type: GET_MENUS,
        menus: response.data, // 응답 데이터 전체 저장
      });
    })
    .catch((error) => {
      console.error("getMenus: API call failed", error);
    });
};

// 좌석 ID 설정 액션
export const getTableId = (tableId) => {
  return {
    type: SET_TABLE_ID,
    payload: tableId,
  };
};
