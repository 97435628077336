import styles from "./styles.module.css";
import forkIcon from "../../assets/images/fork.png";
import continueIcon from "../../assets/images/continue.png";
import pickUpIcon from "../../assets/images/pickUpIcon.png";
import deliveryIcon from "../../assets/images/deliveryIcon.png";
import continueBar from "../../assets/images/continueBar.png";
import pickUpOrderAhead from "../../assets/images/pickUpOrderAhead.png";
import tableOrder from "../../assets/images/tableOrder.png";
import deliveryToYourHome from "../../assets/images/deliveryToYourHome.png";
import engIcon from "../../assets/images/eng.png";

const Main = ({ handleTableClick, storeName }) => {
  return (
    <main className={styles.main}>
      {/* 상단 로고 및 언어 버튼 */}
      <div className={styles["main-top-section"]}>
        <img
          src="/img2/storeLogo2.png"
          alt="Logo"
          className={styles["main-logo"]}
        />
        <img src={engIcon} alt="Language" className={styles["main-language"]} />
      </div>

      {/* 이미지 섹션 */}
      <div className={styles["main-image-section"]}>
        <img
          src="/img2/restaurant.png"
          alt="Restaurant"
          className={styles["main-image"]}
        />
        <div className={styles["welcome-text"]}>
          <h3>Welcome!</h3>
          <p>{storeName || "Unknown Shop"}</p> {/* storeName 표시 */}
        </div>
      </div>

      {/* 진행 버튼 */}
      <div className={styles["main-continue-section"]}>
        <div className={styles["main-continue-text"]}>
          <img src={continueIcon} alt="ContinueArrow" />
          <span>Continue Ordering</span>
        </div>
        <div className={styles["main-continue-bar"]}>
          <img src={continueBar} alt="ContinueBar" />
        </div>
      </div>

      {/* 테이블 오더 */}
      <div className={styles["main-table-section"]} onClick={handleTableClick}>
        <div className={styles["main-table-text"]}>
          <div className={styles["main-table-icon"]}>
            <img src={forkIcon} alt="Fork" />
          </div>
          <div className={styles["main-table-p"]}>
            <p>Eating a meal</p>
            <p>Order at Your Table</p>
          </div>
        </div>
        <div className={styles["main-table-bar"]}>
          <img src={tableOrder} alt="tableBar" />
        </div>
      </div>

      {/* 선택 옵션 */}
      <div className={styles["main-options-container"]}>
        <div className={styles["main-options-section"]}>
          <div className={styles["main-option-text"]}>
            <div className={styles["main-option-icon"]}>
              <img src={pickUpIcon} alt="Pickup" />
            </div>
            <div className={styles["main-option-p"]}>
              <p>Pickup</p>
              <p>Order Ahead</p>
            </div>
          </div>
          <div className={styles["main-option-bar"]}>
            <img src={pickUpOrderAhead} alt="OptionBar" />
          </div>
        </div>
        <div className={styles["main-options-section"]}>
          <div className={styles["main-option-text"]}>
            <div className={styles["main-option-icon"]}>
              <img src={deliveryIcon} alt="Delivery" />
            </div>
            <div className={styles["main-option-p"]}>
              <p>Delivery</p>
              <p>To Your Home</p>
            </div>
          </div>
          <div className={styles["main-option-bar"]}>
            <img src={deliveryToYourHome} alt="OptionBar" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Main;
