import MenuItem from "./MenuItem";
import styles from "./styles.module.css";

const MenuList = ({ categories = [], items = {}, categoryRefs }) => {
  return (
    <div className={styles["menu-items"]}>
      {categories.map((category, index) => (
        <div
          key={index}
          ref={(el) => (categoryRefs[category.id] = el)} // 각 카테고리의 ref 저장
          data-category={category.id}
          className={styles["menu-category"]}
        >
          <h2 className={styles["category-title"]}>{category.name}</h2>
          {(items[category.id] || []).map((item, idx) => (
            <MenuItem key={idx} item={item} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default MenuList;
