import React, { Component } from "react";
import { connect } from "react-redux";
import CategoryList from "../../components/Menu/CategoryList";
import MenuList from "../../components/Menu/MenuList";
import styles from "../../components/Menu/styles.module.css";
import { getMenus } from "../../modules/menu";

class ScrollMenuContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategory: "",
    };

    this.categoryRefs = {}; // ref들을 담을 객체
    this.menuRef = React.createRef();
    this.currentCategoryRef = ""; // 현재 선택된 카테고리
    this.isClicking = false; // 클릭 중인지 여부
    this.headerHeight = 100;
    this.categoryMenuHeight = 66;
  }

  componentDidMount() {
    const { shopId, getMenus, categories } = this.props;

    // 카테고리 초기화
    const firstCategory = categories[0]?.id || "";
    this.setState({ selectedCategory: firstCategory });
    this.currentCategoryRef = firstCategory;

    // 서버에서 메뉴 데이터 불러오기
    getMenus(shopId);

    // 카테고리를 화면에 스크롤
    setTimeout(() => {
      this.scrollCategoryIntoView(firstCategory);
    }, 0);

    window.scrollTo({ top: 0, behavior: "smooth" });
    this.setupIntersectionObserver();
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  setupIntersectionObserver = () => {
    const observerOptions = {
      root: null,
      rootMargin: `-${
        this.headerHeight + this.categoryMenuHeight
      }px 0px -20% 0px`,
      threshold: 0,
    };

    const observerCallback = (entries) => {
      if (this.isClicking) return;

      let mostVisibleCategory = null;
      let mostVisibleDistance = Infinity;
      const viewportCenter = window.scrollY + window.innerHeight / 2;

      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const elementCenter =
            entry.target.offsetTop + entry.target.offsetHeight / 2;
          const distanceToCenter = Math.abs(viewportCenter - elementCenter);

          if (distanceToCenter < mostVisibleDistance) {
            mostVisibleDistance = distanceToCenter;
            mostVisibleCategory = entry.target.dataset.category;
          }
        }
      });

      if (
        mostVisibleCategory &&
        mostVisibleCategory !== this.currentCategoryRef
      ) {
        this.currentCategoryRef = mostVisibleCategory;
        this.setState({ selectedCategory: mostVisibleCategory });

        this.scrollCategoryIntoView(mostVisibleCategory);
      }
    };

    this.observer = new IntersectionObserver(observerCallback, observerOptions);

    Object.values(this.categoryRefs).forEach((element) => {
      if (element) this.observer.observe(element);
    });
  };

  handleCategoryClick = (categoryId) => {
    this.isClicking = true;
    this.setState({ selectedCategory: categoryId });
    this.currentCategoryRef = categoryId;

    // 1. 메뉴 리스트 스크롤 처리
    const categoryElement = this.categoryRefs[categoryId];
    if (categoryElement) {
      const offsetTop =
        categoryElement.offsetTop -
        (this.headerHeight + this.categoryMenuHeight);

      // 세로 스크롤 애니메이션
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }

    // 2. 카테고리 버튼 가로 스크롤 처리
    const activeButton = this.menuRef.current?.querySelector(
      `.${styles["category-button"]}[data-category="${categoryId}"]`
    );

    if (activeButton) {
      const buttonLeft = activeButton.offsetLeft;
      const buttonWidth = activeButton.offsetWidth;
      const menuWidth = this.menuRef.current.offsetWidth;

      // 버튼이 화면 중앙에 오도록 스크롤
      const targetScrollLeft = buttonLeft - menuWidth / 2 + buttonWidth / 2;

      this.menuRef.current.scrollTo({
        left: Math.max(0, targetScrollLeft),
        behavior: "smooth",
      });
    }

    // 클릭 상태 초기화
    setTimeout(() => {
      this.isClicking = false;
    }, 600);
  };

  scrollCategoryIntoView = (category) => {
    const activeButton = this.menuRef.current?.querySelector(
      `.${styles["category-button"]}[data-category="${category}"]`
    );

    if (activeButton) {
      const buttonLeft = activeButton.offsetLeft;
      const buttonWidth = activeButton.offsetWidth;
      const menuWidth = this.menuRef.current.offsetWidth;

      const targetScrollLeft = buttonLeft - menuWidth / 2 + buttonWidth / 2;

      this.menuRef.current.scrollTo({
        left: Math.max(0, targetScrollLeft),
        behavior: "smooth",
      });
    }
  };

  render() {
    const { categories } = this.props;
    const { selectedCategory } = this.state;

    return (
      <div>
        <CategoryList
          categories={categories}
          selectedCategory={selectedCategory}
          onCategoryClick={this.handleCategoryClick}
          menuRef={this.menuRef}
        />
        <MenuList
          categories={this.props.categories} // Redux에서 가져온 categories
          items={this.props.menus} // Redux에서 가져온 menus
          categoryRefs={this.categoryRefs}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ menu }) => ({
  categories: menu.categories.categories || menu.categories || [],
  shopId: menu.shopId,
  menus: menu.menus,
});

const mapDispatchToProps = (dispatch) => ({
  getMenus: (shopId) => dispatch(getMenus(shopId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScrollMenuContainer);
