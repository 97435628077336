import React from "react";
import HeaderContainer from "../containers/common/HeaderContainer";
import ScrollMenuContainer from "../containers/menu/ScrollMenuContainer";

const ScrollMenuPage = () => {
  return (
    <div>
      <HeaderContainer />
      <ScrollMenuContainer />
    </div>
  );
};

export default ScrollMenuPage;
