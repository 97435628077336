import styles from "./styles.module.css";

const Footer = ({
  totalPrice = 0,
  buttonText = "장바구니 담기",
  onButtonClick,
  onPaymentClick = () => {},
}) => {
  return (
    <footer className={styles.footer}>
      <div className={styles["footer-price-box"]}>
        <p>합계</p>
        <p className={styles["footer-price"]}>
          ￦{totalPrice.toLocaleString()}
        </p>
      </div>
      <div className={styles["menu-order-box"]}>
        <button className={styles["cart-btn"]} onClick={onButtonClick}>
          {buttonText}
        </button>
        <button className={styles["order-btn"]} onClick={onPaymentClick}>
          결제하기
        </button>
      </div>
    </footer>
  );
};

export default Footer;
