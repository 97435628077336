import React from "react";
import HeaderContainer from "../containers/common/HeaderContainer";
import MenuDetailContainer from "../containers/menu/MenuDetailContainer";

const MenuDetailPage = () => {
  return (
    <div>
      <HeaderContainer />
      <MenuDetailContainer />
    </div>
  );
};

export default MenuDetailPage;
