import styles from "./styles.module.css";

const CategoryList = ({
  categories = [], // 기본값 설정
  selectedCategory,
  onCategoryClick,
  menuRef,
}) => {
  console.log("Categories in CategoryList:", categories); // 디버깅
  return (
    <div className={styles["category-scroll-menu"]} ref={menuRef}>
      {categories.map((category) => (
        <button
          key={category.id}
          data-category={category.id}
          onClick={() => onCategoryClick(category.id)}
          className={`${styles["category-button"]} ${
            selectedCategory === category.id ? styles["active"] : ""
          }`}
        >
          {category.name}
        </button>
      ))}
    </div>
  );
};

export default CategoryList;
