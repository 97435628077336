import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getCartItems,
  getTotalPrice,
  removeFromCart,
  updateCart,
} from "../modules/cart";
import "./Cart.css";
import deleteIcon from "../assets/images/delete.png";
import FooterContainer from "../containers/common/FooterContainer";
import HeaderContainer from "../containers/common/HeaderContainer";

const Cart = ({ tableId }) => {
  const dispatch = useDispatch();

  console.log("tableId:", tableId);

  const API_URL = process.env.REACT_APP_API_URL;

  const cartItems = useSelector(getCartItems) || [];
  const totalPrice = useSelector(getTotalPrice) || 0;
  const [memo, setMemo] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태 추가

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await fetch(`${API_URL}/cart`);
        if (!response.ok) {
          throw new Error("장바구니 데이터를 불러오는 데 실패했습니다.");
        }
        const items = await response.json();
        dispatch(updateCart(items));
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchCartItems();
  }, [dispatch, API_URL]);

  const handleRemoveItem = (itemId) => {
    dispatch(removeFromCart(itemId));
  };

  const handlePayment = () => {
    setIsModalOpen(true); // 모달 열기
  };

  const closeModal = () => {
    setIsModalOpen(false); // 모달 닫기
  };

  const submitOrder = async () => {
    // 1. 주문 데이터 생성
    const orderData = {
      idx: tableId, // 테이블 ID
      menus: cartItems.map((item) => ({
        idx: item.id, // 메뉴 ID
        quantity: item.quantity, // 수량
        options: null, // 옵션 (현재 사용하지 않음)
      })),
    };

    console.log("보낼 주문 데이터:", orderData);

    try {
      // 2. 서버로 데이터 전송
      const response = await fetch(`${API_URL}/api/order`, {
        method: "POST", // HTTP 메서드
        headers: {
          "Content-Type": "application/json", // JSON 형식
        },
        body: JSON.stringify(orderData), // JSON 문자열로 변환
      });

      // 3. 응답 상태 확인
      if (!response.ok) {
        const errorText = await response.text();
        console.error("서버 응답 에러:", errorText);
        throw new Error(`서버 요청 실패: ${response.status}`);
      }

      // 4. JSON 응답 처리
      const result = await response.json();
      console.log("서버 응답 데이터:", result);

      // 5. 성공 메시지 표시
      alert("주문이 완료되었습니다!");
      closeModal(); // 모달 닫기
    } catch (error) {
      // 에러 처리
      console.error("주문 요청 중 에러 발생:", error.message);
      alert("주문 처리 중 문제가 발생했습니다. 다시 시도해주세요.");
    }
  };

  return (
    <div>
      <HeaderContainer />
      <div className="cart">
        <h2 className="cart-title">Basket</h2>
        <div className="cart-container">
          {cartItems.map((item) => (
            <div key={item.id} className="cart-item">
              <img
                src={item.imgUrl}
                alt={item.name}
                className="cart-item-img"
              />
              <div className="cart-item-details">
                <p className="cart-item-name">{item.name}</p>
                <p className="cart-item-price">
                  ￦{(item.price * item.quantity).toLocaleString()}
                </p>
              </div>
              <div
                className="cart-item-remove"
                onClick={() => handleRemoveItem(item.id)}
              >
                <img src={deleteIcon} alt="삭제" />
              </div>
            </div>
          ))}
          <div className="cart-summary">
            <div className="cart-tip">
              <button className="tip-button">Tip</button>
              <button className="tip-button active">5%</button>
              <button className="tip-button">10%</button>
              <button className="tip-button">15%</button>
            </div>
            <div className="cart-total">
              <p>총 가격</p>
              <h3>￦{totalPrice.toLocaleString()}</h3>
            </div>
          </div>
        </div>
        <div className="cart-info">
          <div className="cart-info-box">
            <p>Table Location</p>
            <h4>{tableId}</h4>
          </div>
          <div className="cart-info-box">
            <p>Nickname</p>
            <h4>Ben</h4>
          </div>
          <div className="cart-info-box cart-memo-box">
            <p>Memo</p>
            <textarea
              className="cart-memo"
              placeholder="Please serve all the ordered food at once"
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
            />
          </div>
        </div>
      </div>
      <FooterContainer handlePayment={handlePayment} totalPrice={totalPrice} />

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>결제하기</h2>
            <p>총 금액: ￦{totalPrice.toLocaleString()}</p>
            <button className="confirm-btn" onClick={submitOrder}>
              결제 완료
            </button>
            <button className="close-btn" onClick={closeModal}>
              닫기
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("리덕스 state.tableId:", state.menu.tableId); // Redux 상태의 tableId 값 확인
  return {
    tableId: state.menu.tableId, // Redux에서 tableId 가져오기
  };
};

export default connect(mapStateToProps)(Cart);
